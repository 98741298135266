<template>
  <div class="class-info">
    <TopBar :type="2" :pageTitle="'我的班级'" />
    <!-- <p class="class-title">{{ classInfo.name }}</p> -->
    <div class="line"></div>
    <div class="class_main">
      <div class="class_cont">
        <div class="info_show">
          <p class="title_name">{{ classData.name }}</p>
          <div class="deep_pros">
            <div class="left">
              <p class="label">课程进度{{ classData.course_rate }}%</p>
              <div class="pross_fir">
                <p :style="{ width: classData.course_rate + '%' }"></p>
              </div>
            </div>
            <div class="right">有效期至{{ classData.end_time }}</div>
          </div>
          <template v-if="isWeixin">
            <div v-if="typeIndex == 1 && classData.cert_url && siteInfo.is_open_class_cert == 1" class="cert_cont"><a
                @click="bookImgState = true">证书下载</a> </div>
            <div v-if="typeIndex == 2 && classData.cert_url" class="cert_cont"><a @click="bookImgState = true">证书下载</a>
            </div>
          </template>
          <template v-else>
            <div v-if="typeIndex == 1 && classData.cert_url && siteInfo.is_open_class_cert == 1" class="cert_cont"><a
                :href="classData.cert_url">证书下载</a> </div>
            <div v-if="typeIndex == 2 && classData.cert_url" class="cert_cont"><a :href="classData.cert_url">证书下载</a>
            </div>
          </template>

        </div>
        <div class="exam_info">
          <p class="text">考试情况</p>
          <div class="right_text">
            <p class="text1">{{ classData.exam_success }}项已通过</p>
            <p class="text2">{{ classData.exam_fail }}项未通过</p>
          </div>
        </div>
      </div>
      <ul class="class-list">
        <li class="title">班级课程</li>
        <li class="class-list-info" v-for="item in classCourse" :key="item.id">
          <router-link :to="'/recordedInfo/' + item.id">
            <img class="info-img" :src="item.image" alt="" />
            <div class="info-content">
              <div class="info-title vertical-ellipsis">{{ item.name }}</div>
              <div class="progress">
                <div class="num">{{ item.rate }}</div>
                <div class="progress-bg">
                  <div class="progress-line" :style="{ width: item.rate }"></div>
                </div>
              </div>
            </div>
          </router-link>
        </li>
        <li class="no-data" v-if="classCourse && classCourse.length == 0">
          班级内还没有课程
        </li>
      </ul>
      <ul class="class-list">
        <li class="title">文本课程</li>
        <li class="class-list-info" v-for="item in textCourseList" :key="item.id" @click="goTextDetail(item.id)">
          <!-- <router-link :to="'/textCourse/detai?id=' + item.id"> -->
          <img class="info-img" :src="item.image" alt="" />
          <div class="info-content">
            <div class="info-title vertical-ellipsis">{{ item.title }}</div>

          </div>
          <!-- </router-link> -->
        </li>
        <li class="no-data" v-if="textCourseList && textCourseList.length == 0">
          班级内还没有课程
        </li>
      </ul>
    </div>
    <ul class="test-list">
      <li class="title">班级考试</li>
      <li class="test-info" v-if="classInfo && classInfo.id == '302' && typeIndex == '1'">
        <div class="test-title vertical-ellipsis">培训心得</div>
        <div class="btns">
          <router-link :to="'/textarea'" class="button blue">去考试</router-link>
        </div>
      </li>
      <li class="test-info" v-for="item in classExam" :key="item.id">
        <div class="test-title vertical-ellipsis">{{ item.exam_name }}</div>
        <div class="test-date">{{ item.start_time }} — {{ item.end_time }}</div>
        <div class="btns">
          <div class="residue">
            剩余考试次数：<span class="num">{{ item.times - item.count }}次</span>
          </div>
          <router-link :to="'/text/' + item.id + '/' + typeIndex" class="button blue">去考试</router-link>
          <button class="button grey" @click="viewScoreBtn(item.id, item.exam_name)">
            查看分数
          </button>
        </div>
      </li>
      <li class="no-data" v-if="classExam && classExam.length == 0 && (classInfo && classInfo.id != '302')">
        班级内还没有考试
      </li>
    </ul>
    <div class="view-score" v-if="viewScore">
      <div class="score-content">
        <!-- <div class="close" @click="viewScore=false"></div> -->
        <div class="score-title">
          {{ viewScoreTitle }}
          <span class="close" @click="viewScore = false"></span>
        </div>
        <ul class="my-table">
          <li class="table-th">
            <span class="table-td" style="width: 58px">id</span>
            <span class="table-td" style="width: 170px">考试时间</span>
            <span class="table-td" style="width: 90px">分数</span>
          </li>
          <li class="table-tr" v-for="(item) in queryScore.list" :key="item.created_at">
            <span class="table-td" style="width: 58px">{{ item.id }}</span>
            <span class="table-td" style="width: 170px">{{item.created_at}}</span>
            <span class="table-td" style="width: 90px">{{queryScore.is_show_score==1 ? item.score : '--'}}</span>
            <p class="btn" v-if="queryScore.is_show_score=1" @click="examDetail(item)">查看</p>
          </li>
          <li class="table-tr nodata" v-if="queryScore.list.length == 0">
            <span class="table-td">暂无分数</span>
          </li>
        </ul>
      </div>
    </div>

    <!-- 证书图片下载弹框 -->
    <div class="mask_cont_box" v-if="bookImgState">
      <div class="cont_data">
        <p class="close" @click="bookImgState = false"><img src="@/assets/m/wrong-black.png" alt=""></p>
        <p class="tips">长按图片保存到手机</p>
        <p class="img">
          <img :src="classData.cert_url" alt="">
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import TopBar from "@/m/common/topBar.vue";
export default {
  name: "ClassInfo",
  data() {
    return {
      classInfo: "", //班级信息
      classSelect: "", //选中班级
      classCourse: null, //课程列表
      classExam: null, //考试列表
      viewScore: false, //查看分数弹窗
      viewScoreTitle: "", //弹窗标题
      queryScore: "", //分数数据
      classData: "",
      typeIndex: '', //1班级  2开发式班级
      siteInfo: '',
      textCourseList: [],//文本课程列表
      classId: '',//班级id
      isWeixin: '',//判断是不是微信环境
      bookImgState: false,//证书图片弹框
    };
  },
  components: {
    TopBar,
  },
  mounted() {
    this.siteInfo = JSON.parse(localStorage.getItem('siteInfo')) ? JSON.parse(localStorage.getItem('siteInfo')) : '';
    this.classId = this.$route.params.id
    this.typeIndex = this.$route.params.type
    if (this.typeIndex == 2) {
      this.getTextCourseList()
    }

    this.getClassInfo();
    let ua = window.navigator.userAgent.toLowerCase();
    //判断是不是微信
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      // 微信
      this.isWeixin = true
    } else {
      this.isWeixin = false
    }
  },
  methods: {
    //获取班级列表
    getClassInfo() {
      let classId = this.classId
      let url = this.typeIndex == '1' ? '/v1/personal/classList' : '/v1/openClass/classList'
      this.$axios
        .post(url, {}, { useLog: true })
        .then((res) => {
          if (res.data.list.length > 0) {
            for (let i = 0; i < res.data.list.length; i++) {
              if (res.data.list[i].id == classId) {
                this.classInfo = res.data.list[i];
              }
            }
          }

          // this.getClassCourse(this.classInfo.id);
          this.getClassData(this.classInfo.id);
          // this.getClassExam(this.classInfo.id);
        });
    },
    // 获取文本课程
    getTextCourseList() {
      this.$axios.post(`/v1/openClass/classTextCourse`, { class_id: this.classId }, { useLog: true }).then(res => {
        if (res.code == 0) {
          this.textCourseList = res.data
        }
      })
    },
    // 文本课程-跳转到详情
    goTextDetail(id) {
      this.$router.push('/textCourse/detail?id=' + id)
    },
    // 获取班级下的信息
    getClassData(id) {
      let url = this.typeIndex == '1' ? '/v1/personal/myClass' : '/v1/openClass/myClass'

      this.$axios
        .post(
          url,
          {
            class_id: id,
          },
          { useLog: true }
        )
        .then((res) => {
          if (res.data.cert_url) {
            res.data.cert_url = res.data.cert_url.replace("http:", "https:");
          }
          this.classData = res.data;
          this.classCourse = res.data.courseList;
          this.classExam = res.data.examList;
        });
    },
    //获取班级课程
    getClassCourse(id) {
      let url = this.typeIndex == '1' ? '/v1/personal/myClass' : '/v1/openClass/myClass'

      this.$axios
        .post(
          `/v1/personal/classCourse`,
          {
            class_id: id,
          },
          { useLog: true }
        )
        .then((res) => {
          this.classCourse = res.data;
        });
    },
    //获取班级考试
    getClassExam(id) {
      let url = this.typeIndex == '1' ? '/v1/personal/classExam' : '/v1/openClass/classExam'

      this.$axios
        .post(
          url,
          {
            class_id: id,
          },
          { useLog: true }
        )
        .then((res) => {
          this.classExam = res.data;
        });
    },
    //查看分数
    viewScoreBtn(id, name) {
      let url = this.typeIndex == '1' ? '/v1/personal/queryScore' : '/v1/openClass/queryScore'

      this.$axios
        .post(
          url,
          {
            exam_id: id,
          },
          { useLog: true }
        )
        .then((res) => {
          this.viewScore = true;
          this.viewScoreTitle = name;
          this.queryScore = res.data;
        });
    },
    examDetail(row) {
      this.$router.push('/userCenter/exam?id=' + row.id + '&type=' + this.typeIndex)
    },
  },
};
</script>

<style scoped lang="scss">
.class-title {
  padding: 28px;
  font-size: 28px;
  color: #333;
}

// .line {
//   width: 100%;
//   height: 10px;
//   background: #f5f5f5;
// }
.class_main {
  .class_cont {
    background: #fff;
    box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.08);

    .info_show {
      width: 100%;
      min-height: 276px;
      background: linear-gradient(90deg, #0065df 0%, #55a2ff 100%);
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .title_name {
        font-size: 30px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
        text-align: center;
        padding-top: 54px;
      }

      .deep_pros {
        padding: 36px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        div.left {
          p.label {
            font-size: 24px;
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
          }

          .pross_fir {
            margin-top: 16px;
            position: relative;
            width: 333px;
            height: 16px;
            background: #eeeeee;
            border-radius: 124px 124px 124px 124px;

            p {
              position: absolute;
              left: 0;
              top: 0;
              background: #eda220;
              border-radius: 124px 124px 124px 124px;
              height: 100%;
            }
          }
        }

        div.right {
          padding-top: 10px;
          font-size: 24px;
          font-family: Source Han Sans CN-Normal, Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }

      // 证书下载
      .cert_cont {
        padding: 0 36px 36px;

        a {
          color: #fff;
          font-size: 25px;
          // border: 1px solid #999;
          padding: 15px 25px;
          border-radius: 5px;
          background: orange;
        }
      }
    }

    .exam_info {
      display: flex;
      padding: 16px 36px;
      justify-content: space-between;

      p.text {
        font-size: 24px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #999999;
      }

      div.right_text {
        display: flex;
        align-items: center;

        p {
          font-size: 24px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #579f33;
        }

        p.text2 {
          color: #e15416;
          margin-left: 25px;
        }
      }
    }
  }
}

.title {
  width: 100%;
  line-height: 44px;
  font-size: 32px;
  margin-bottom: 28px;
}

.class-list {
  margin-top: 20px;
}

.class-list,
.test-list {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 28px;

  &::after {
    content: "";
    display: block;
    width: 28%;
  }
}

.class-list-info {
  width: 335px;
  box-shadow: 0px 0px 12px 0px rgba(0, 12, 40, 0.14);
  border-radius: 8px;
  margin-bottom: 24px;

  .info-img {
    width: 100%;
    height: 186px;
  }

  .info-content {
    padding: 9px 16px 24px 20px;
  }

  .info-title {
    height: 70px;
    font-size: 28px;
    color: #333;
    line-height: 35px;
  }

  .progress {
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .num {
      font-size: 24px;
      color: #000;
      text-shadow: 0px 0px 50px rgba(0, 0, 0, 0.3);
    }

    .progress-bg,
    .progress-line {
      width: 230px;
      height: 7px;
      background: #e5e5e5;
      box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.3);
      border-radius: 3px;
    }

    .progress-line {
      background: #254ed4;
    }
  }
}

.test-info {
  box-sizing: border-box;
  width: 100%;
  padding: 38px 28px;
  box-shadow: 0px 0px 10px 0px rgba(0, 12, 40, 0.14);
  border-radius: 6px;
  margin-bottom: 28px;

  .test-title {
    width: 100%;
    max-height: 70px;
    font-size: 28px;
    color: #333;
    line-height: 35px;
  }

  .test-date {
    font-size: 24px;
    height: 30px;
    line-height: 30px;
    color: #999;
    padding-left: 40px;
    background: url(~@/assets/clock-ico.png) no-repeat left center #fff;
    background-size: auto 30px;
    margin-top: 20px;
  }

  .btns {
    overflow: hidden;
    margin-top: 48px;

    .button {
      display: block;
      box-sizing: border-box;
      padding: 0;
      width: 128px;
      height: 52px;
      border-radius: 6px;
      line-height: 52px;
      font-size: 26px;
      text-align: center;
      float: right;
      margin-left: 20px;

      &.grey {
        border: 1px solid #999;
        color: #666;
      }
    }
  }

  .residue {
    font-size: 24px;
    color: #999;
    line-height: 52px;
    float: left;

    .num {
      color: #333;
    }
  }
}

.view-score {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);

  .score-content {
    box-sizing: border-box;
    width: 100%;
    max-height: 600px;
    padding: 30px;
    background-color: #fff;
    position: absolute;
    left: 0;
    bottom: 0;

    .score-title {
      font-size: 32px;
      color: #333;
      margin-bottom: 60px;
      position: relative;

      .close {
        display: block;
        width: 50px;
        height: 50px;
        background: url(~@/assets/close-grey-ico.png) no-repeat center center;
        background-size: auto 23px;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .close {
      width: 30px;
      height: 30px;
      background: url(~@/assets/close-ico.png) no-repeat;
      background-size: 100%;
      position: absolute;
      right: -50px;
      top: 0;
      cursor: pointer;
    }
  }
}

.my-table {
  box-sizing: border-box;
  max-height: 352px;
  overflow-y: auto;
  border-radius: 6px;

  .table-th,
  .table-tr {
    height: 88px;

    &.nodata {
      width: 100%;
      justify-content: center;
    }

    .table-td {
      font-size: 26px;
    }

    p.btn {
      color: #0065df;
    }
  }

  .table-th {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: sticky;
    top: 0;
  }
}

.no-data {
  width: 100%;
  text-align: center;
  color: #999;
}

.mask_cont_box {
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;

  .cont_data {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    // height: 80vh;
    background: #fff;
    border-radius: 10px;
    padding-bottom: 10px;

    p.close {
      display: flex;
      justify-content: flex-end;
      margin: 15px;

      img {
        width: 40px;
        height: 40px;
      }
    }

    p.tips {
      text-align: center;
      font-size: 28px;
    }

    p.img {
      width: 95%;
      margin: 0 auto;

      img {
        height: auto;
        width: 100%;
      }
    }
  }
}
</style>
